import React from 'react';

function AboutComponent() {
  return (
    <div className='container'>
      <div className='my-3 about'>
        <h1>About Us</h1>
        <br />
        <p>Welcome to <strong>GetJobs.info</strong> – your gateway to finding the perfect job!</p>
        <p>At <strong>GetJobs.info</strong>, we are committed to simplifying the job search process by providing a unified platform where job seekers can easily discover opportunities that match their skills, preferred location, job title, and the companies they aspire to work for.</p>
        <h3>What We Do</h3>
        <p>Our mission is to connect job seekers with employers seamlessly. We aggregate job listings from various companies and present them in a user-friendly manner, making it easier for you to find the jobs that are right for you. We believe that finding a job shouldn’t be a daunting task, and our platform is designed to eliminate the hassle by allowing you to search and filter job openings based on criteria that matter to you.</p>
        <h3>How It Works</h3>
        <p>On <strong>GetJobs.info</strong>, you won’t find lengthy job descriptions or detailed pages for each listing. Instead, we provide a direct link to the original job posting on the company’s website. This ensures that you always get the most accurate and up-to-date information straight from the source. With just a few clicks, you can view the job details and apply directly on the company’s official site, saving you time and ensuring a smooth application process.</p>
        <h3>Why Choose Us?</h3>
        <ul>
          <li>
            <strong>Comprehensive Search Options:</strong> Whether you’re looking for a specific job title, a particular location, or jobs at a certain company, our search filters help you find exactly what you’re looking for with ease.
          </li>
          <li>
            <strong>Direct Access to Job Listings:</strong> We prioritize your convenience by linking you directly to the company’s job posting, ensuring you get all the information you need from the employer itself.
          </li>
          <li>
            <strong>No Clutter, Just Opportunities:</strong> Our platform is streamlined to focus on what matters most – connecting you with job opportunities without unnecessary distractions.
          </li>
        </ul>
        <h3>For Employers</h3>
        <p>We also provide an invaluable service to companies looking to attract the right talent. By listing your job openings on <strong>GetJobs.info</strong>, you can increase visibility and reach a larger pool of qualified candidates. All we need is a simple Excel sheet with key details such as job title, required experience, location, and skills, and we’ll take care of the rest – at no cost to you. Our platform helps bridge the gap between employers and job seekers efficiently and effectively.</p>
        <h3>Our Purpose</h3>
        <p>At <strong>GetJobs.info</strong>, our purpose is clear: to create a straightforward, effective, and efficient platform that connects job seekers with the opportunities they seek, while helping companies find the talent they need. We’re here to make the job search process easier for everyone involved, and we’re dedicated to helping you succeed in your career journey.</p>
        <p>Thank you for choosing <strong>GetJobs.info</strong>. We’re excited to be a part of your job search and look forward to helping you find your next great opportunity.</p>
        <br/>
      </div>
    </div>
  );
}

export default AboutComponent;