import React from 'react';
import SkillsComponent from '../skills';
import ExperienceComponent from '../experience';

function JobComponent(props) {
    const { job_title, company_name, location, experience, skills, link } = props.data;
    return (
        <a href={link} target='_blank' className='list-group-item list-group-item-action'>
            <div className='d-flex w-100 justify-content-between'>
                <div className='d-flex flex-column'>
                    <h5 className='mb-1'>{job_title}</h5>
                    <p className='mb-3'>by <span className='company-name'>{company_name}</span> at {location}</p>
                </div>
            </div>
            <div className='d-flex flex-wrap w-100 justify-content-start align-items-center mb-2 gap-2'>
                {experience && <ExperienceComponent experience={experience} />}
                {skills && <SkillsComponent skills={skills} />}
            </div>
        </a>
    );
}

export default JobComponent;