import React from 'react';
import experience from '../../experience.svg';

function ExperienceComponent(props) {
    return (
        <span className='experience-badge-tag'>
            <div className='d-flex justify-content-start align-items-center'>
                <img src={experience} height={18} className='me-2' />
                <span>{props.experience}</span>
            </div>
        </span>
    );
}

export default ExperienceComponent;