import React from 'react';

function SkillsComponent(props) {
    return (
        <React.Fragment>
            {props.skills.split(',').map((skill, index) => {
                return (
                    <span className='skills-badge-tag' key={index}>{skill}</span>
                )
            })}
        </React.Fragment>
    )
}

export default SkillsComponent;