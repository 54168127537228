import React from 'react';
import JobComponent from '../job';

function JobsListComponent(props) {
    const { jobs } = props;
    return (
        <div className='container'>
            <div className='list-group list-group-flush my-3'>
                {jobs.map((item) => <JobComponent key={item.id} data={item} />)}
            </div>
        </div>
    );
}

export default JobsListComponent;