import React from 'react';

function PrivacyComponent() {
  return (
    <div className='container'>
      <div className='my-3 privacy'>
        <h1>Privacy Policy</h1>
        <br />
        <p>At <strong>GetJobs.info</strong>, your privacy is our priority. This Privacy Policy outlines our commitment to protecting your privacy and explains that we do not collect, store, or process any personal information from our users.</p>

        <h3>No Data Collection</h3>
        <p><strong>GetJobs.info</strong> does not collect any personal or non-personal data from users. This includes but is not limited to:</p>
        <ul>
          <li><strong>Personal Information:</strong> We do not collect names, email addresses, or any other personally identifiable information.</li>
          <li><strong>Usage Data:</strong> We do not track or store information such as IP addresses, browser types, or pages visited.</li>
          <li><strong>Cookies:</strong> We do not use cookies or any other tracking technologies on our website.</li>
        </ul>

        <h3>Job Listings and External Links</h3>
        <p>Our website provides a platform for users to browse job listings. These listings include links that redirect to the original source of the job posting on external websites. Once you leave our website and visit an external site, the privacy policy of that external site will apply.</p>

        <h3>Changes to This Privacy Policy</h3>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the date of the last update will be indicated at the top.</p>

        <h3>Contact Us</h3>
        <p>If you have any questions or concerns about this Privacy Policy, please feel free to contact us at <a href="mailto:support@getjobs.info">support@getjobs.info</a>.</p>
        <br/>
      </div>
    </div>
  );
}

export default PrivacyComponent;