import React from 'react';

function ContactComponent() {
  return (
    <div className='container'>
      <div className='my-3 contact'>
        <h1>Contact Us</h1>
        <br />
        <p>At <strong>GetJobs.info</strong>, we’re here to help you with any questions, feedback, or support you may need. Whether you’re a job seeker looking for assistance or a company interested in listing your job openings, we’re just a message away.</p>

        <h3>Get in Touch</h3>
        <p><strong>General Inquiries:</strong><br />
          If you have any questions about our platform, need help with your job search, or want to learn more about how GetJobs.info can help your company, feel free to reach out to us. We’re always happy to assist.</p>
        <p><strong>Email:</strong> <a href="mailto:support@getjobs.info">support@getjobs.info</a>{/* <br />
          <strong>Phone:</strong> +91-XXXXXXXXXX */}</p>

        <h3>For Job Seekers</h3>
        <p>Are you having trouble finding the right job, or do you need help navigating our platform? Our team is ready to assist you. Whether it’s a technical issue or a general question, we’ll ensure you get the support you need to continue your job search smoothly.</p>

        <h3>For Employers</h3>
        <p>Interested in listing your job openings on GetJobs.info? We make it easy for you. Simply provide us with an Excel file containing key details like job title, required experience, location, and skills, and we’ll take care of the rest. There are no charges to list your jobs on our platform, and we’re here to help you attract the right talent.</p>
        <p><strong>Email:</strong> <a href="mailto:listings@getjobs.info">listings@getjobs.info</a></p>

        {/* <h3>Connect with Us</h3>
        <p>We’d love to hear from you! Follow us on social media to stay updated with the latest job listings, tips, and news.</p>
        <p><strong>Facebook:</strong> <a href="#">facebook.com/GetJobs.info</a><br />
          <strong>Twitter:</strong> <a href="#">twitter.com/GetJobs_info</a><br />
          <strong>LinkedIn:</strong> <a href="#">linkedin.com/company/getjobs-info</a></p>

        <h3>Office Address</h3>
        <p>If you need to reach us by mail or visit us in person, you can find us at:</p>
        <p><strong>GetJobs.info</strong><br />
          1234 Business Avenue,<br />
          Tech Park, Bangalore,<br />
          Karnataka, India - 560001</p>
 */}
 
        <h3>Support Hours</h3>
        <p>Our support team is available Monday to Friday, from 9 AM to 6 PM IST. We strive to respond to all inquiries within 24 hours.</p>
        <br/>
      </div>
    </div>
  );
}

export default ContactComponent;