import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import FooterComponent from './components/footer';
import HeaderComponent from './components/header';
import HomeComponent from './components/home';
import AboutComponent from './components/about';
import ContactComponent from './components/contact';
import PrivacyComponent from './components/privacy';
import ScrollTopComponent from './components/scroll-top';

function App() {
  return (
    <Router>
      <ScrollTopComponent />
      <div id='page-container'>
        <div id='content-wrap'>
          <HeaderComponent />
          <Routes>
            <Route path="/" element={<HomeComponent />} />
            <Route path="/about" element={<AboutComponent />} />
            <Route path="/contact" element={<ContactComponent />} />
            <Route path="/privacy" element={<PrivacyComponent />} />\
          </Routes>
        </div>
        <footer id='footer'>
          <FooterComponent />
        </footer>
      </div>
    </Router>
  );
}

export default App;
