import React from 'react';
import { Link } from 'react-router-dom';
import facebook from '../../facebook.png';
import twitter from '../../twitter.png';
import linkedin from '../../linkedin.png';

function FooterComponent() {
    return (
        <div className='simple-footer'>
            <div className='container'>
                <div className='d-flex flex-wrap justify-content-lg-between justify-content-center align-items-center gap-2'>
                    <p>&copy; 2024 DevTa Inc. All rights reserved.</p>
                    <div className='footer-links'>
                        <Link to='/'>Home</Link>
                        <Link to='/about'>About</Link>
                        <Link to='/contact'>Contact</Link>
                        <Link to='/privacy'>Privacy Policy</Link>
                    </div>
                    <div className='social-icons'>
                        <a href='https://facebook.com' target='_blank'><img src={facebook} alt='Facebook' /></a>
                        <a href='https://twitter.com' target='_blank'><img src={twitter} alt='Twitter' /></a>
                        <a href='https://linkedin.com' target='_blank'><img src={linkedin} alt='LinkedIn' /></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterComponent;