import React, { useState, useEffect } from 'react';
import axios from 'axios';
import JobsListComponent from '../jobs-list';

function HomeComponent() {
    const [jobs, setJobs] = useState([]);
    const [keywords, setKeywords] = useState('');
    const [inputKeywords, setInputKeywords] = useState('');

    const fetchData = () => {
        axios.post('https://getjobs.info/api.php', {
            keywords,
        }).then((response) => {
            setJobs(response.data);
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setInputKeywords(keywords);
        fetchData(keywords);
    };

    return (
        <React.Fragment>
            <section className='career-intro'>
                <div className='container'>
                    <h1 className='career-heading'>Find Your Perfect Career Path</h1>
                    <p className='career-subheading'>Explore opportunities with leading employers and start applying to your dream job today.</p>
                    <form className='search-form' method='post' onSubmit={handleSubmit}>
                        <input type='text' className='search-input' name='keywords' id='keywords' onChange={e => setKeywords(e.target.value)} value={keywords} placeholder='Search jobs by title, skills, company, or location...' />
                        <button type='button' className='search-button' onClick={handleSubmit}>Search</button>
                    </form>
                </div>
            </section>
            <div className='container'>
                <div className='my-3'>
                    {inputKeywords ? `Showing ${jobs.length} results for: ${inputKeywords}` : 'Showing all available results'}
                </div>
            </div>
            <JobsListComponent jobs={jobs} />
        </React.Fragment>
    );
}

export default HomeComponent;